@import '../../../variables.scss';
.footer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: fixed;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  bottom: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 40px 156px 55px;
  z-index: 10;

  @media screen and (max-width: 1600px) {
    padding: 40px 80px 55px;
  }
  @media screen and (max-width: 1024px) {
    padding: 40px 80px 50px;
  }
  @media screen and (max-width: 1600px) and (min-width: 1040px) and (max-height: 800px) {
    padding: 20px 80px 20px;
  }
  @media screen and (max-width: 600px) {
    padding: 25px 27px 55px;
  }

  &__link {
    @media screen and (max-width: 1040px) {
      display: none;
    }
  }

  &__icons {
    display: flex;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    // width: 30px;
    // height: 30px;
    &::after {
      content: '';
      display: block;
      border-radius: 50%;
      width: 4px;
      height: 4px;
      background: #da4f4f;
      margin: 0 45px;
    }

    &--end {
      &::after {
        display: none;
      }
      img {
        cursor: pointer;
      }
    }
    a {
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &--linktree {
      img {
        width: 26px;
        height: 26px;
        object-fit: cover;
        border: 1px solid #7c83a1;
        border-radius: 7px;
      }
    }
  }

  &__copyright {
    @include fontText(normal, 400, 18px, 22px);

    color: #52597a;
  }

  @media screen and (max-width: 940px) {
    padding-bottom: 32px;
    display: flex;
    flex-direction: column;
    &__icon {
      &::after {
        margin: 0 27px;
      }
    }
    &__icons {
      margin-bottom: 28px;
    }
  }
  @media screen and (max-width: 360px) {
    &__icon {
      &::after {
        margin: 0 15px;
      }
    }
    &__copyright {
      @include fontText(normal, 400, 16px, 18px);

      color: #52597a;
    }
  }
}
