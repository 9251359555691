@import '../../variables.scss';

@keyframes showBlock {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideBlock {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.songs-page {
  //border: 1px solid red;
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  height: max-content;
  margin: auto 0;
  padding: 40px 0;

  &__link {
    text-decoration: underline;
    white-space: nowrap;
  }
  @media screen and (max-width: 1600px) and (min-width: 1040px) and (max-height: 800px) {
    padding: 20px 0;
  }

  @media screen and (max-width: 1024px) {
    display: flex;
    height: 100%;
    flex-grow: 1;
    position: relative;
    flex-direction: column;
  }

  @media screen and (max-width: 600px) {
    padding: 60px 0 40px;
  }
  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(25, 27, 37, 0.0727273) 0%,
      rgba(25, 27, 37, 0) 64.06%,
      #191b25 100%
    );
  }

  &__list {
    //overflow-y: scroll;
    height: 450px;
    flex-shrink: 0;

    @include scroll();
    direction: rtl;

    &--mobile {
      display: none;
    }

    @media screen and (max-width: 1440px) {
      height: 300px;
    }
    @media screen and (max-width: 1024px) {
      display: none;
      &--mobile {
        width: 100%;
        display: block;
        height: auto;
        overflow: hidden;
        overflow-y: auto;
      }
    }
  }

  &__song-name {
    @include fontText(normal, 300, 38px, 59px);
    color: #7c83a1;

    @media screen and (max-width: 1440px) {
      @include fontText(normal, 300, 32px, 126%);
    }
    @media screen and (max-width: 1024px) {
      @include fontText(normal, 300, 24px, 36px);
      text-align: center;
    }
  }

  &__song-date {
    @include fontText(normal, 600, 14px, 17px);
    text-transform: uppercase;
    color: #7c83a1;

    @media screen and (max-width: 1024px) {
      text-align: center;
    }
  }

  &__li {
    // border: 1px solid blue;
    height: 120px;
    margin-left: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    position: relative;
    cursor: pointer;

    &--active {
      .songs-page__song-name {
        color: #da4f4f;
      }

      .songs-page__song-date {
        color: #da4f4f;
      }

      &::after {
        content: '';
        display: block;
        width: 3px;
        height: 100%;
        background: #da4f4f;

        position: absolute;
        left: -70px;
        top: 0;
      }
    }

    &--disabled {
      opacity: 0.3;
      pointer-events: none;
    }

    @media screen and (max-width: 1440px) {
      height: 100px;
      margin-left: 30px;

      &--active {
        &::after {
          content: '';
          display: block;
          width: 3px;
          height: 100%;
          background: #da4f4f;

          position: absolute;
          left: -30px;
          top: 0;
        }
      }
    }
    @media screen and (max-width: 1024px) {
      margin: 0;
      height: auto;
      margin-bottom: 40px;
      &--active {
        &::after {
          content: '';
          display: block;
          width: 3px;
          height: 100%;
          background: #da4f4f;

          position: absolute;
          left: -30px;
          top: 0;
        }
      }
    }
  }

  &__container {
    width: 100%;
    max-width: 1000px;
    margin-left: 40px;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  &__audio-info {
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1320px) {
      flex-direction: column;
    }
  }
  &__audio-info-container {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__audio-field-container {
    margin-right: 50px;

    @media screen and (max-width: 1320px) {
      margin-right: 30px;
    }
  }

  &__audio-field {
    display: flex;
    margin-bottom: 8px;
    @media screen and (max-width: 1024px) {
      flex-direction: column;
    }
  }

  &__audio-title {
    @include fontText(normal, 400, 18px, 22px);

    color: #52597a;
    flex-shrink: 0;
    width: 265px;

    @media screen and (max-width: 1024px) {
      @include fontText(normal, 300, 24px, 36px);
      text-align: center;
      margin-bottom: 8px;
      width: 100%;
    }
    @media screen and (max-width: 600px) {
      @include fontText(normal, 300, 21px, 120%);
      text-align: center;
      margin-bottom: 8px;
      width: 100%;
    }
  }
  &__audio-value {
    @include fontText(normal, 400, 18px, 22px);
    margin-left: 10px;
    color: #7c83a1;

    span {
      white-space: nowrap;
    }

    @media screen and (max-width: 1024px) {
      @include fontText(normal, 300, 24px, 36px);
      display: block;
      text-align: center;
      margin-bottom: 40px;
    }
    @media screen and (max-width: 600px) {
      @include fontText(normal, 300, 21px, 120%);
      display: block;
      text-align: center;
      margin-bottom: 40px;
      span {
        display: inline-block;
      }
    }
  }

  &__icon {
    width: 32px;
    height: 32px;
    margin-right: 50px;
    &:last-of-type {
      margin-right: 0;
    }

    &--disabled {
      pointer-events: none;
      cursor: not-allowed;
      text-decoration: none;
      color: black;
      display: none;
      // img {
      //   filter: grayscale(80%);
      // }
    }
  }

  &__icon-container {
    display: flex;

    a {
      width: 32px;
      height: 32px;
      margin-right: 50px;
      &:last-of-type {
        margin-right: 0;
      }
    }

    @media screen and (max-width: 1320px) {
      width: 100%;
      justify-content: right;
      margin-top: 10px;
    }
    @media screen and (max-width: 1024px) {
      width: 100%;
      justify-content: center;
      margin: 60px 0 100px;
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      justify-content: center;
      margin: 40px 0 60px;
    }
  }

  // MOBILE
  &__menu {
    display: none;

    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: 1024px) {
      display: flex;
    }
    &--entering {
      //ввод
      //opacity: 0.5;
      position: absolute;
      top: 0;
      width: 100%;
      opacity: 0;
    }
    &--entered {
      //введен
      // background-color: blue;
      animation: showBlock 1s forwards;
      //opacity: 0;
    }
    &--exiting {
      //выход
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      animation: hideBlock 1s forwards;
    }
    &--exited {
      //вышел
      opacity: 0;
    }
  }

  &__title {
    @include fontText(normal, 400, 72px, 88px);
    text-transform: uppercase;
    margin: 0;
    margin-bottom: 72px;
    margin-top: 100px;

    color: #da4f4f;

    @media screen and (max-width: 600px) {
      @include fontText(normal, 300, 48px, 59px);
      margin-bottom: 56px;
      margin-top: 60px;
    }
  }

  &__audio-container {
    width: 100%;
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
    }

    &--entering {
      //ввод
      //opacity: 0.5;
      opacity: 0;
    }
    &--entered {
      //введен
      animation: showBlock 1s forwards;
      //opacity: 0;
    }
    &--exiting {
      //выход

      animation: hideBlock 1s forwards;
    }
    &--exited {
      //вышел
      opacity: 0;
    }
  }

  &__name-mobile {
    @include fontText(normal, 300, 48px, 59px);
    text-transform: uppercase;
    width: 100%;
    text-align: center;
    margin: 100px 0;

    color: #da4f4f;

    @media screen and (max-width: 600px) {
      @include fontText(normal, 300, 32px, 39px);
      margin: 40px 0;
    }
  }

  &__waves-background {
    display: none;

    @media screen and (max-width: 1024px) {
      display: block;
      position: fixed;
      bottom: 120px;
      left: 0;
      z-index: -2;
      width: 100%;
    }
  }

  &__shadow-up {
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100px;
    background: linear-gradient(
      180deg,
      #191b25 60%,
      rgba(25, 27, 37, 0.0727273) 100.92%
    );
    z-index: 2;
  }

  &__shadow-down {
    position: fixed;
    bottom: 0;
    right: 0;
    // border: 1px solid red;
    width: 100%;
    height: 150px;
    background: linear-gradient(
      180deg,
      rgba(25, 27, 37, 0) 1%,
      #191b25 20.69%,
      #191b25 100%
    );
    z-index: 2;

    @media screen and (max-width: 1600px) and (min-width: 1040px) and (max-height: 800px) {
      height: 100px;
    }
  }
}
