@import '../../variables.scss';
@keyframes hideBlock1 {
  0% {
    left: 0%;
  }
  100% {
    left: -100%;
  }
}
@keyframes showBlock1 {
  0% {
    left: -100%;
  }
  100% {
    left: 0%;
  }
}
@keyframes showBlock2 {
  0% {
    top: -200vh;
  }
  100% {
    top: 0%;
  }
}
@keyframes hideBlock2 {
  0% {
    top: 0%;
  }
  100% {
    top: -200vh;
  }
}
@keyframes hideBlockTextRight {
  0% {
    right: 0%;
  }
  100% {
    right: -100vw;
  }
}
@keyframes hideBlockTextLeft {
  0% {
    right: 0%;
  }
  100% {
    right: 100vw;
  }
}
@keyframes showBlockTextRight {
  0% {
    right: -100vw;
  }
  100% {
    right: 0;
  }
}
@keyframes showBlockTextLeft {
  0% {
    right: 100vw;
  }
  100% {
    right: 0;
  }
}
@keyframes hideBlockTextBottom {
  0% {
    bottom: auto;
    top: 300px;
  }
  100% {
    bottom: auto;
    top: 100vh;
  }
}
@keyframes hideBlockTextTop {
  0% {
    bottom: auto;
    top: 300px;
  }
  100% {
    bottom: auto;
    top: -100vh;
  }
}
@keyframes showBlockTextTop {
  0% {
    bottom: auto;
    top: 100vh;
  }
  100% {
    bottom: auto;
    top: 300px;
  }
}
@keyframes showBlockTextBottom {
  0% {
    bottom: auto;
    top: -100vh;
  }
  100% {
    bottom: auto;
    top: 300px;
  }
}
@keyframes hideBlockTextBottomMobile {
  0% {
    bottom: 140px;
  }
  100% {
    bottom: -50vh;
  }
}
@keyframes hideBlockTextTopMobile {
  0% {
    bottom: 140px;
    opacity: 1;
  }
  100% {
    bottom: 100vh;
    opacity: 140px;
  }
}
@keyframes showBlockTextTopMobile {
  0% {
    bottom: -50vh;
  }
  100% {
    bottom: 140px;
  }
}
@keyframes showBlockTextBottomMobile {
  0% {
    bottom: 100vh;
  }
  100% {
    bottom: 140px;
  }
}
@keyframes showBlock4 {
  0% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0;
  }
  30% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0.2;
  }
  100% {
    font-size: 660px;
    top: 20%;
    right: 20%;
    opacity: 1;
  }
}
@keyframes hideBlock4 {
  0% {
    font-size: 660px;
    top: 20%;
    right: 20%;
    opacity: 1;
  }
  100% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes leftBlock4 {
  0% {
    font-size: 660px;
    top: 20%;
    right: 20%;
  }
  100% {
    font-size: 660px;
    top: 20%;
    right: 120%;
  }
}
@keyframes rightBlock4 {
  0% {
    font-size: 660px;
    top: 20%;
    right: 120%;
  }
  100% {
    font-size: 660px;
    top: 20%;
    right: 20%;
  }
}
@keyframes showBlock4-2 {
  0% {
    font-size: 66px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
  30% {
    font-size: 66px;
    top: 40%;
    right: 20%;
    opacity: 0.2;
  }
  100% {
    font-size: 500px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
}
@keyframes hideBlock4-2 {
  0% {
    font-size: 500px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
  100% {
    font-size: 66px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes leftBlock4-2 {
  0% {
    font-size: 500px;
    top: 10%;
    right: 20%;
  }
  100% {
    font-size: 500px;
    top: 10%;
    right: 120%;
  }
}
@keyframes rightBlock4-2 {
  0% {
    font-size: 500px;
    top: 10%;
    right: 120%;
  }
  100% {
    font-size: 500px;
    top: 10%;
    right: 20%;
  }
}
@keyframes showBlock4-3 {
  0% {
    font-size: 500px;
    top: 100%;
    left: -5%;
  }
  100% {
    font-size: 500px;
    top: 0%;
    left: -5%;
  }
}
@keyframes hideBlock4-3 {
  0% {
    font-size: 500px;
    top: 0%;
    left: -5%;
  }
  100% {
    font-size: 500px;
    top: 100%;
    left: -5%;
  }
}
@keyframes leftBlock4-3 {
  0% {
    font-size: 500px;
    top: 0%;
    left: -5%;
  }
  100% {
    font-size: 500px;
    top: -50%;
    left: -5%;
  }
}
@keyframes rightBlock4-3 {
  0% {
    font-size: 500px;
    top: -50%;
    left: -5%;
  }
  100% {
    font-size: 500px;
    top: 0%;
    left: -5%;
  }
}
@keyframes showBlock4-4 {
  0% {
    font-size: 150px;
    top: 100%;
    left: 15px;
  }
  100% {
    font-size: 150px;
    top: 15%;
    left: 15px;
  }
}
@keyframes hideBlock4-4 {
  0% {
    font-size: 150px;
    top: 15%;
    left: 15px;
  }
  100% {
    font-size: 150px;
    top: 100%;
    left: 15px;
  }
}
@keyframes leftBlock4-4 {
  0% {
    font-size: 150px;
    top: 15%;
    left: 15px;
  }
  100% {
    font-size: 150px;
    top: -50%;
    left: 15px;
  }
}
@keyframes rightBlock4-4 {
  0% {
    font-size: 150px;
    top: -50%;
    left: 15px;
  }
  100% {
    font-size: 150px;
    top: 15%;
    left: 15px;
  }
}
@keyframes showBlock5 {
  0% {
    font-size: 80px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
  30% {
    font-size: 80px;
    top: 40%;
    right: 20%;
    opacity: 0.2;
  }
  100% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 1;
  }
}
@keyframes hideBlock5 {
  0% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 1;
  }
  100% {
    font-size: 80px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes leftBlock5 {
  0% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 1;
  }
  100% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 0;
  }
}
@keyframes rightBlock5 {
  0% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 0;
  }
  100% {
    font-size: 300px;
    top: 15%;
    right: 15%;
    opacity: 1;
  }
}
@keyframes showBlock5-2 {
  0% {
    font-size: 60px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
  30% {
    font-size: 60px;
    top: 40%;
    right: 20%;
    opacity: 0.2;
  }
  100% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
}
@keyframes hideBlock5-2 {
  0% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
  100% {
    font-size: 60px;
    top: 40%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes leftBlock5-2 {
  0% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
  100% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes rightBlock5-2 {
  0% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 0;
  }
  100% {
    font-size: 200px;
    top: 10%;
    right: 20%;
    opacity: 1;
  }
}
@keyframes showBlock5-3 {
  0% {
    top: 100%;
    left: 15px;
  }
  100% {
    top: 15%;
    left: 15px;
  }
}
@keyframes hideBlock5-3 {
  0% {
    top: 15%;
    left: 15px;
  }
  100% {
    top: 100%;
    left: 15px;
  }
}
@keyframes leftBlock5-3 {
  0% {
    top: 15%;
    left: 15px;
  }
  100% {
    top: -50%;
    left: 15px;
  }
}
@keyframes rightBlock5-3 {
  0% {
    top: -50%;
    left: 15px;
  }
  100% {
    top: 15%;
    left: 15px;
  }
}
@keyframes showBlock19 {
  0% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0;
  }
  30% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0.3;
  }
  100% {
    font-size: 350px;
    top: 30%;
    right: 20%;
    opacity: 1;
  }
}
@keyframes hideBlock19 {
  0% {
    font-size: 350px;
    top: 30%;
    right: 20%;
    opacity: 1;
  }
  100% {
    font-size: 66px;
    top: 50%;
    right: 20%;
    opacity: 0;
  }
}
@keyframes leftBlock19 {
  0% {
    font-size: 350px;
    top: 30%;
    right: 20%;
  }
  100% {
    font-size: 350px;
    top: 30%;
    right: 120%;
  }
}
@keyframes rightBlock19 {
  0% {
    font-size: 350px;
    top: 30%;
    right: 120%;
  }
  100% {
    font-size: 350px;
    top: 30%;
    right: 20%;
  }
}
@keyframes showBlock19-2 {
  0% {
    font-size: 44px;
    top: 50%;
    right: 25%;
    opacity: 0;
  }
  30% {
    font-size: 44px;
    top: 50%;
    right: 25%;
    opacity: 0.3;
  }
  100% {
    font-size: 250px;
    top: 15%;
    right: 25%;
    opacity: 1;
  }
}
@keyframes hideBlock19-2 {
  0% {
    font-size: 250px;
    top: 15%;
    right: 25%;
    opacity: 1;
  }
  100% {
    font-size: 44px;
    top: 50%;
    right: 25%;
    opacity: 0;
  }
}
@keyframes leftBlock19-2 {
  0% {
    font-size: 250px;
    top: 15%;
    right: 25%;
  }
  100% {
    font-size: 250px;
    top: 15%;
    right: 120%;
  }
}
@keyframes rightBlock19-2 {
  0% {
    font-size: 250px;
    top: 15%;
    right: 120%;
  }
  100% {
    font-size: 250px;
    top: 15%;
    right: 25%;
  }
}
@keyframes showBlock19-3 {
  0% {
    top: 100%;
    left: 15px;
  }
  100% {
    top: 15%;
    left: 15px;
  }
}
@keyframes hideBlock19-3 {
  0% {
    top: 15%;
    left: 15px;
  }
  100% {
    top: 100%;
    left: 15px;
  }
}
@keyframes leftBlock19-3 {
  0% {
    top: 15%;
    left: 15px;
  }
  100% {
    top: -50%;
    left: 15px;
  }
}
@keyframes rightBlock19-3 {
  0% {
    top: -50%;
    left: 15px;
  }
  100% {
    top: 15%;
    left: 15px;
  }
}
@keyframes showBlockTextOpacity {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes hideBlockTextOpacity {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
$time: 2s;
$time2: 1s;
$time0: 4s;
.about-page {
  width: 100%;
  flex-grow: 1;
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  // overscroll-behavior: none;

  // &__anim--step-1 {
  //   overscroll-behavior: auto;
  // }

  &::after {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(25, 27, 37, 0.0727273) 0%,
      rgba(25, 27, 37, 0) 64.06%,
      #191b25 100%
    );
  }
  &::before {
    content: '';
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: -1;
    background: linear-gradient(
      180deg,
      rgba(25, 27, 37, 0.0727273) 0%,
      rgba(25, 27, 37, 0) 74.06%,
      #191b25 100%
    );
    transform: rotate(180deg);
    @media screen and (max-width: 1040px) {
      background: linear-gradient(
        180deg,
        rgba(25, 27, 37, 0.0727273) 0%,
        rgba(25, 27, 37, 0) 90.06%,
        #191b25 100%
      );
    }
  }
  &__line {
    display: none;
    @media screen and (max-width: 600px) {
      display: block;
      position: fixed;
      z-index: -1;
      top: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        180deg,
        rgba(25, 27, 37, 0.0727273) 0%,
        rgba(25, 27, 37, 0) 48.96%,
        rgba(25, 27, 37, 0.8) 76.04%,
        #191b25 100%
      );
    }
  }
  &__block-container {
    width: 100%;
    flex-grow: 1;
    display: flex;
    position: relative;
    transition: left ease-in $time;
    &--entering {
      animation: showBlock1 $time forwards;
    }
    &--exiting {
      animation: hideBlock1 $time forwards;
    }
    @media screen and (max-width: 1040px) {
      // top: 100px;
      .about-page__block-1 {
        margin-top: 5%;
      }
      .about-page__scroll-container {
        display: none;
      }
    }
    @media screen and (max-width: 600px) {
      position: fixed;
      bottom: 140px;
      top: auto;

      .about-page__text {
        @include fontText(normal, 300, 36px, 48px);
      }
    }
  }

  &__block-container-2 {
    width: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-items: flex-end;
    position: relative;
  }
  &__img-block-1 {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -2;
    height: 100%;
    img {
      height: 100%;
    }
    transition: right ease-in $time, img {
      height: 100%;
    }
    &--entering {
      transition: right ease-in $time, transform ease-in $time;
      transform: scale(1);
      right: 0%;
    }
    &--exited {
      right: 50%;
      transform: scale(1.25);
    }
    &--entered {
      right: 0%;
      transform: scale(1);
    }
    &--exiting {
      transition: right ease-in $time, transform ease-in $time;
      right: 50%;
      transform: scale(1.25);
    }
    @media screen and (max-width: 1600px) {
      &--entering {
        transition: right ease-in $time, transform ease-in $time;
        transform: scale(1);
        right: -10%;
      }
      &--exited {
        right: 50%;
        transform: scale(1.25);
      }
      &--entered {
        right: -10%;
        transform: scale(1);
      }
      &--exiting {
        transition: right ease-in $time, transform ease-in $time;
        right: 50%;
        transform: scale(1.25);
      }
    }
    @media screen and (max-width: 1040px) {
      &--entering {
        transition: right ease-in $time, transform ease-in $time;
        right: 0%;
        transform: scale(1);
      }
      &--exited {
        right: 0%;
        transform: scale(1);
      }
      &--entered {
        right: 0%;
        transform: scale(1);
      }
      &--exiting {
        transition: right ease-in $time, transform ease-in $time;
        right: 0%;
        transform: scale(1);
      }
    }
    @media screen and (max-width: 940px) {
      &--entering {
        transition: right ease-in $time, transform ease-in $time;
        right: -10%;
        transform: scale(1);
      }
      &--exited {
        right: -10%;
        transform: scale(1);
      }
      &--entered {
        right: -10%;
        transform: scale(1);
      }
      &--exiting {
        transition: right ease-in $time, transform ease-in $time;
        right: -10%;
        transform: scale(1);
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
      right: 0;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      transition: all ease-in $time;
    }
  }
  &__anim--step-5 {
    .about-page__img-block-1 {
      right: 200vw;
      @media screen and (max-width: 1040px) {
        right: -100vw;
      }
      @media screen and (max-width: 600px) {
        right: 0;
        opacity: 0;
      }
    }
  }
  &__img-block-2 {
    position: fixed;
    bottom: 0;
    right: 0;
    z-index: -2;
    height: 100%;
    img {
      height: 100%;
    }
    @media screen and (min-width: 1600px) {
      &--entering {
        right: -120vw;
        transition: all ease-out $time2;
      }
      &--entered {
        right: 15%;
        transition: all ease-out $time2;
      }
      &--exiting {
        transition: all ease-in $time;
        right: -100vw;
      }
    }
    @media screen and (max-width: 1600px) {
      right: auto;
      left: -20%;
      &--entering {
        right: auto;
        left: 100vw;
        transition: all ease-out $time2;
      }
      &--entered {
        right: auto;
        left: -5%;
        transition: all ease-out $time;
      }
      &--exiting {
        transition: all ease-in $time;
        right: auto;
        left: 100vw;
      }
    }
    @media screen and (max-width: 1300px) {
      right: auto;
      left: -20%;
      &--entering {
        right: auto;
        left: 100vw;
        transition: all ease-out $time2;
      }
      &--entered {
        right: auto;
        left: -20%;
        transition: all ease-out $time;
      }
      &--exiting {
        transition: all ease-in $time;
        right: auto;
        left: 100vw;
      }
    }
    @media screen and (max-width: 1040px) {
      right: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &--entering {
        right: 0;
        left: 0;
        opacity: 0;
        transition: all ease-out $time2;
      }
      &--entered {
        right: 0;
        left: 0;
        opacity: 1;
        transition: all ease-out $time;
      }
      &--exiting {
        transition: all ease-in $time;
        right: 0;
        left: 0;
        opacity: 0;
      }
    }
    @media screen and (max-width: 940px) {
      right: 0;
      left: -10%;
      width: 110%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &--entering {
        right: 0;
        left: -10%;
        opacity: 0;
        transition: all ease-out $time2;
      }
      &--entered {
        right: 0;
        left: -10%;
        opacity: 1;
        transition: all ease-out $time;
      }
      &--exiting {
        transition: all ease-in $time;
        right: 0;
        left: -10%;
        opacity: 0;
      }
    }
    @media screen and (max-width: 600px) {
      width: 100%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      &--entering {
        right: 0;
        left: 0;
        opacity: 0;
        transition: all ease-out $time2;
      }
      &--entered {
        right: 0;
        left: 0;
        opacity: 1;
        transition: all ease-out $time;
      }
      &--exiting {
        transition: all ease-in $time;
        right: 0;
        left: 0;
        opacity: 0;
      }
    }
  }
  &__text {
    @include fontText(normal, 300, 48px, 59px);
    color: #da4f4f;
    // margin-top: 22%;
    margin-bottom: 45px;
  }
  &__scroll-container {
    display: flex;
    align-items: center;
  }
  &__scroll-text {
    @include fontText(normal, 400, 18px, 22px);
    color: #52597a;
    margin-right: 16px;
  }
  &__scroll {
    display: flex;
    align-items: center;
    position: relative;
    width: 262px;
    height: 1px;
    background-color: #52597a;
  }
  &__scroll-line {
    width: 0%;
    height: 1px;
    background: #da4f4f;
    transition: all ease-in $time;
    &--1 {
      width: 0%;
    }
    &--2 {
      width: 25%;
    }
    &--3 {
      width: 50%;
    }
    &--4 {
      width: 75%;
    }
    &--5 {
      width: 100%;
    }
  }
  &__scroll-pointer {
    position: absolute;
    top: -3px;
    left: 0px;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #da4f4f;
    transition: all ease-in $time;
    &--1 {
      left: 0%;
    }
    &--2 {
      left: 25%;
    }
    &--3 {
      left: 50%;
    }
    &--4 {
      left: 75%;
    }
    &--5 {
      left: 100%;
    }
    @media screen and (max-width: 1040px) {
      left: -3px;
      &--1 {
        top: 0%;
      }
      &--2 {
        top: 25%;
      }
      &--3 {
        top: 50%;
      }
      &--4 {
        top: 75%;
      }
      &--5 {
        top: 100%;
      }
    }
  }
  &__tablet-scroll-container {
    display: none;
    @media screen and (max-width: 1040px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: absolute;
      // bottom: 707px;
      bottom: 0;
      left: 0;
      //transform: rotate(90deg);
      .about-page__scroll {
        // width: 51vh;
        position: relative;
        width: 1px;
        height: 51vh;
      }
      .about-page__tablet-scroll-line {
        // width: 33%;
        position: absolute;
        top: 0;
        width: 1px;
        height: 1px;
        background: #da4f4f;
        transition: height ease-in $time;
        &--1 {
          height: 0%;
        }
        &--2 {
          height: 25%;
        }
        &--3 {
          height: 50%;
        }
        &--4 {
          height: 75%;
        }
        &--5 {
          height: 100%;
        }
      }
      .about-page__scroll-text {
        margin-top: 20px;
        margin-right: 0;
        writing-mode: vertical-lr;
        transform: rotate(180deg);
      }
      .about-page__tablet-scroll-pointer {
        position: absolute;
        top: -3px;
        left: -2px;
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: #da4f4f;
      }
    }
    @media screen and (max-width: 1040px) and (max-height: 1100px) {
      .about-page__scroll {
        // width: 51vh;
        position: relative;
        width: 1px;
        height: 40vh;
      }
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__container {
    display: flex;
    align-items: flex-end;
    justify-content: center;
    padding: 0 156px 0;
    margin-top: 100px;
    .about-page__block-1 {
      display: block;
    }
    @media screen and (max-width: 1600px) {
      padding: 0 80px 0;
    }
    @media screen and (max-width: 1300px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 1040px) {
      .about-page__block-1 {
        display: none;
      }
      margin-top: 0;
    }
  }
  &__block-1 {
    display: none;
    margin-top: 15%;
    //margin-top: 180px;

    @media screen and (max-width: 1920px) {
      margin-top: 10%;
    }

    @media screen and (max-height: 900px) {
      margin-top: 5%;
    }
    @media screen and (max-width: 1040px) {
      display: block;
      margin-top: 15%;
    }
  }
  &__circle-1 {
    display: block;
    width: 933px;
    height: 933px;
    position: absolute;
    bottom: 0;
    left: 35%;
    background: #52597a;
    filter: blur(500px);
    z-index: -3;
    @media screen and (min-width: 1040px) {
      &--entering {
        //ввод
        transition: all ease-in $time;
        bottom: 0;
        left: 35%;
      }
      &--exited {
        left: -40%;
        bottom: 15%;
      }
      &--entered {
        bottom: 0;
        left: 40%;
      }
      &--exiting {
        //выход
        transition: all ease-in $time;
        left: -35%;
        bottom: 15%;
      }
    }
    @media screen and (max-width: 1040px) {
      bottom: -60%;
      right: -20%;
      left: auto;
    }
    @media screen and (max-width: 600px) {
      width: 582px;
      height: 582px;
      bottom: -50%;
      right: -20%;
      left: auto;
      filter: blur(300px);
    }
  }
  &__block-2-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    z-index: -2;
    &--entering {
      animation: showBlock2 $time forwards;
    }
    &--exiting {
      animation: hideBlock2 $time forwards;
    }
  }
  &__block-2 {
    max-width: 1920px;
    width: 100%;
    height: 100%;
    display: flex;
    max-height: 100vh;
    flex-direction: column;
    justify-content: center;
    padding: 80px 0 200px;
    @media screen and (max-width: 1920px) {
      padding: 80px 0 100px;
    }
    @media screen and (max-height: 768px) {
      padding: 80px 0 100px;
    }
    @media screen and (max-width: 600px) {
      padding: 0;
      justify-content: flex-start;
    }
  }
  &__block-2-first-name {
    // @include fontTitle(none, 800, 200px, 150%);
    font-weight: 800;
    text-align: right;
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 270px;
    line-height: 100%;
    //@include fontText(normal, 800, 220px, 100%);
    color: #1a1c28;
    position: relative;
    text-transform: uppercase;
    top: 10%;
    // top: 100px;
    right: 5%;
    color: #1c1e29;
    // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    @media screen and (max-width: 1920px) {
      font-size: calc(40px + 10vw);
    }
    @media screen and (max-width: 1040px) {
      top: 5%;
    }
    @media screen and (max-width: 600px) {
      display: none;
    }
  }
  &__block-2-full-name {
    width: 100%;
    // font-weight: 800;
    text-align: center;
    color: #1a1c28;
    text-transform: uppercase;
    color: #1c1e29;
    // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    // @include fontText(normal, 800, 450px, 100%);
    font-family: 'Poppins';
    font-weight: 900;
    font-size: 450px;
    line-height: 100%;
    position: relative;
    left: -10px;
    //top: 0;
    //top: 5%;
    @media screen and (max-width: 1920px) {
      font-size: calc(45px + 22vw);
      // top: 7%;
    }
    @media screen and (max-width: 1000px) {
      font-size: calc(40px + 22vw);
    }
    @media screen and (max-width: 600px) {
      left: auto;
      // display: none;
      position: relative;
      top: 20px;
      left: 15px;
      font-size: calc(24px + 20vw);
      right: auto;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
    }
    // @include fontTitle(none, 800, 480px, 150%);
  }
  &__block-3 {
    position: absolute;
    bottom: 100px;
    right: -100vw;
    //right: 0;
    align-self: flex-end;
    width: 539px;
    // position: relative;
    // top: 25%;
    // right: -100%;
    z-index: 1;
    .about-page__text {
      margin-bottom: 100px;
      margin-top: 0;
      @include fontText(normal, 300, 32px, 48px);
      span {
        color: #aaafc6;
      }
    }
    .about-page__scroll {
      display: flex;
      align-items: center;
      position: relative;
      width: 474px;
      height: 1px;
      background-color: #52597a;
    }
    &--entering {
      //ввод
      right: -100vw;
    }
    &--exited {
      right: 0;
    }
    &--entered {
      right: 0;
      transition: all ease-in $time2;
    }
    &--exiting {
      //выход
      right: -100vw;
      transition: all ease-in $time;
    }
    @media screen and (max-height: 900px) {
      bottom: 20px;
    }
    @media screen and (max-width: 1040px) {
      display: none;
    }
  }
  &__text-block {
    @include fontText(normal, 300, 32px, 48px);
    color: #da4f4f;
    span {
      color: #aaafc6;
    }
    // margin-top: 22%;
    margin-bottom: 45px;
    width: 539px;
    @media screen and (max-width: 1040px) {
      width: 380px;
      @include fontText(normal, 300, 26px, 40px);
    }
    @media screen and (max-width: 600px) {
      @include fontText(normal, 300, 24px, 36px);
      width: 100%;
    }
  }
  &__block-6 {
    position: absolute;
    bottom: 100px;
    right: -100vw;
    margin-bottom: 100px;
    display: none;
    z-index: 1;
    &.about-page__text-block {
      padding-right: 119px;
    }
    &--0-1 {
      display: block;
      animation: showBlockTextRight $time ease-in;
      right: 0;
    }
    &--1-0 {
      display: block;
      animation: hideBlockTextRight $time ease-in;
      right: -100vw;
    }
    &--1-2 {
      display: block;
      animation: hideBlockTextLeft $time ease-in;
      right: 100vw;
    }
    &--2-1 {
      display: block;
      animation: showBlockTextLeft $time ease-in;
      right: 0;
    }
    @media screen and (max-height: 900px) and (min-width: 1040px) {
      bottom: 20px;
    }
    @media screen and (max-width: 1040px) {
      bottom: 20px;
      &.about-page__text-block {
        padding-right: 0;
      }
      &--0-1 {
        display: block;
        animation: showBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--1-0 {
        display: block;
        animation: hideBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--1-2 {
        display: block;
        animation: hideBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--2-1 {
        display: block;
        animation: showBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
      &.about-page__text-block {
        padding-right: 0;
      }
      position: fixed;
      left: 27px;
      width: calc(100% - 54px);
      &--0-1 {
        display: block;
        animation: showBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--1-0 {
        display: block;
        animation: hideBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--1-2 {
        display: block;
        animation: hideBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--2-1 {
        display: block;
        animation: showBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
    }
  }
  &__block-7 {
    position: absolute;
    bottom: 100px;
    right: -100vw;
    margin-bottom: 100px;
    display: none;
    z-index: 1;
    &--1-2 {
      display: block;
      animation: showBlockTextRight $time ease-in;
      right: 0;
    }
    &--2-1 {
      display: block;
      animation: hideBlockTextRight $time ease-in;
      right: -100vw;
    }
    &--2-3 {
      display: block;
      animation: hideBlockTextLeft $time ease-in;
      right: 100vw;
    }
    &--3-2 {
      display: block;
      animation: showBlockTextLeft $time ease-in;
      right: 0;
    }
    @media screen and (max-height: 900px) {
      bottom: 20px;
    }
    @media screen and (max-width: 1040px) {
      bottom: 20px;
      &--1-2 {
        display: block;
        animation: showBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--2-1 {
        display: block;
        animation: hideBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--2-3 {
        display: block;
        animation: hideBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--3-2 {
        display: block;
        animation: showBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
      &.about-page__text-block {
        padding-right: 0;
      }
      position: fixed;
      left: 27px;
      width: calc(100% - 54px);
      &--1-2 {
        display: block;
        animation: showBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--2-1 {
        display: block;
        animation: hideBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--2-3 {
        display: block;
        animation: hideBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--3-2 {
        display: block;
        animation: showBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
    }
  }
  &__block-8 {
    position: absolute;
    bottom: 100px;
    right: -100vw;
    margin-bottom: 100px;
    display: none;
    z-index: 1;
    &.about-page__text-block {
      padding-right: 60px;
    }
    &--2-3 {
      display: block;
      animation: showBlockTextRight $time ease-in;
      right: 0;
    }
    &--3-2 {
      display: block;
      animation: hideBlockTextRight $time ease-in;
      right: -100vw;
    }
    &--3-4 {
      display: block;
      animation: hideBlockTextLeft $time ease-in;
      right: 100vw;
    }
    &--4-3 {
      display: block;
      animation: showBlockTextLeft $time ease-in;
      right: 0;
    }
    @media screen and (max-height: 900px) {
      bottom: 20px;
    }
    @media screen and (max-width: 1040px) {
      &.about-page__text-block {
        padding-right: 0;
      }
      bottom: 20px;
      &--2-3 {
        display: block;
        animation: showBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--3-2 {
        display: block;
        animation: hideBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--3-4 {
        display: block;
        animation: hideBlockTextTop $time ease-in forwards;
        right: auto;
        left: 10%;
      }
      &--4-3 {
        display: block;
        animation: showBlockTextBottom $time ease-in forwards;
        right: auto;
        left: 10%;
      }
    }
    @media screen and (max-width: 600px) {
      margin-bottom: 0;
      &.about-page__text-block {
        padding-right: 0;
      }
      position: fixed;
      left: 27px;
      width: calc(100% - 54px);
      &--2-3 {
        display: block;
        animation: showBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--3-2 {
        display: block;
        animation: hideBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--3-4 {
        display: block;
        animation: hideBlockTextTopMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
      &--4-3 {
        display: block;
        animation: showBlockTextBottomMobile $time ease-in forwards;
        right: auto;
        margin-bottom: 0;
      }
    }
  }
  &__block-9 {
    position: absolute;
    bottom: 100px;
    right: -100vw;
    margin-bottom: 100px;
    .about-page__text-block {
      padding-right: 50px;
    }
    display: none;
    z-index: 1;
    &--3-4 {
      display: block;
      animation: showBlockTextRight $time ease-in;
      right: 0;
    }
    &--4-3 {
      display: block;
      animation: hideBlockTextRight $time ease-in;
      right: -100vw;
    }
    @media screen and (max-height: 900px) {
      bottom: 50px;
    }
    @media screen and (max-height: 800px) {
      margin-bottom: 50px;
    }
    @media screen and (max-width: 1040px) {
      bottom: auto;
      top: 10%;
      right: 0;
      left: auto;
      .about-page__text-block {
        padding-right: 0;
      }
      &--3-4 {
        display: block;
        animation: showBlockTextOpacity $time ease-in forwards;
      }
      &--4-3 {
        display: block;
        animation: hideBlockTextOpacity $time ease-in forwards;
      }
    }
    @media screen and (max-width: 600px) {
      position: fixed;
      top: auto;
      bottom: 140px;
      right: auto;
      left: auto;
      margin-bottom: 0;
      .about-page__text-block {
        padding-right: 0;
      }
      &--3-4 {
        display: block;
        animation: showBlockTextOpacity $time ease-in forwards;
      }
      &--4-3 {
        display: block;
        animation: hideBlockTextOpacity $time ease-in forwards;
      }
    }
  }
  &__button {
    display: block;
    @include fontText(normal, 600, 18px, 22px);
    color: #da4f4f;
    padding: 18px 40px;
    border: 1px solid #7c83a1;
    border-radius: 40px;
    background: none;
  }
  &__block-4 {
    width: max-content;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* identical to box height, or 528px */
    letter-spacing: -0.05em;
    color: #1c1e29;
    // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    //color: #1a1c28;
    // color: #1c1e29;
    // color: red;
    // // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    // //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    // color: #232531;
    // text-shadow: 1.24065px 2.48131px 14.8878px rgba(24, 27, 37, 0.25),
    //   0px 31.1826px 77.9565px rgba(4, 5, 12, 0.6);
    font-size: 66px;
    position: fixed;
    top: 50%;
    right: 20%;
    z-index: -2;
    transition: all linear $time0;
    &--0-1 {
      animation: showBlock4 $time0 forwards;
    }
    &--1-0 {
      animation: hideBlock4 $time0 forwards;
    }
    &--1-2 {
      animation: leftBlock4 $time0 forwards;
    }
    &--2-1 {
      animation: rightBlock4 $time0 forwards;
    }
    @media screen and (max-width: 1600px) {
      &--0-1 {
        animation: showBlock4-2 $time0 forwards;
      }
      &--1-0 {
        animation: hideBlock4-2 $time0 forwards;
      }
      &--1-2 {
        animation: leftBlock4-2 $time0 forwards;
      }
      &--2-1 {
        animation: rightBlock4-2 $time0 forwards;
      }
    }
    @media screen and (max-width: 1040px) {
      top: 100vh;
      right: auto;
      left: -5%;
      &--0-1 {
        animation: showBlock4-3 $time0 forwards;
      }
      &--1-0 {
        animation: hideBlock4-3 $time0 forwards;
      }
      &--1-2 {
        animation: leftBlock4-3 $time0 forwards;
      }
      &--2-1 {
        animation: rightBlock4-3 $time0 forwards;
      }
    }
    @media screen and (max-width: 600px) {
      right: auto;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      font-size: 150px;
      left: 15px;
      line-height: max-width;
      &--0-1 {
        animation: showBlock4-4 $time0 forwards;
      }
      &--1-0 {
        animation: hideBlock4-4 $time0 forwards;
      }
      &--1-2 {
        animation: leftBlock4-4 $time0 forwards;
      }
      &--2-1 {
        animation: rightBlock4-4 $time0 forwards;
      }
    }
  }
  &__block-5 {
    width: max-content;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 900;
    font-size: 660px;
    line-height: 80%;
    /* identical to box height, or 528px */
    letter-spacing: -0.05em;
    // color: #1c1e29;
    color: #1c1e29;
    // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    // color: #232531;
    // text-shadow: 1.24065px 2.48131px 14.8878px rgba(24, 27, 37, 0.25),
    //   0px 31.1826px 77.9565px rgba(4, 5, 12, 0.6);
    position: fixed;
    font-size: 80px;
    top: 40%;
    right: 20%;
    z-index: -2;
    transition: all linear $time0;
    &--1-2 {
      animation: showBlock5 $time0 forwards;
    }
    &--2-1 {
      animation: hideBlock5 $time0 forwards;
    }
    &--2-3 {
      animation: leftBlock5 $time0 forwards;
    }
    &--3-2 {
      animation: rightBlock5 $time0 forwards;
    }
    @media screen and (max-width: 1600px) {
      &--1-2 {
        animation: showBlock5-2 $time0 forwards;
      }
      &--2-1 {
        animation: hideBlock5-2 $time0 forwards;
      }
      &--2-3 {
        animation: leftBlock5-2 $time0 forwards;
      }
      &--3-2 {
        animation: rightBlock5-2 $time0 forwards;
      }
    }
    @media screen and (max-width: 1040px) {
      font-size: 150px;
      top: 100vh;
      right: auto;
      left: 15px;
      &--1-2 {
        animation: showBlock5-3 $time0 forwards;
      }
      &--2-1 {
        animation: hideBlock5-3 $time0 forwards;
      }
      &--2-3 {
        animation: leftBlock5-3 $time0 forwards;
      }
      &--3-2 {
        animation: rightBlock5-3 $time0 forwards;
      }
    }
    @media screen and (max-width: 600px) {
      font-size: 80px;
      right: auto;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      line-height: 100%;
    }
  }
  &__block-19 {
    width: max-content;
    font-family: 'Cera Pro';
    font-style: normal;
    font-weight: 900;
    line-height: 80%;
    /* identical to box height, or 528px */
    letter-spacing: -0.05em;
    color: #1c1e29;
    // text-shadow: 3.90274px 7.80547px 46.8328px rgba(24, 27, 37, 0.25),
    //   0px 98.0915px 245.229px rgba(4, 5, 12, 0.6);
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    color: #1a1c28;
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);
    // color: #232531;
    // text-shadow: 1.24065px 2.48131px 14.8878px rgba(24, 27, 37, 0.25),
    //   0px 31.1826px 77.9565px rgba(4, 5, 12, 0.6);
    font-size: 66px;
    position: fixed;
    top: 50%;
    right: 20%;
    z-index: -2;
    transition: all linear $time0;
    &--2-3 {
      animation: showBlock19 $time0 forwards;
    }
    &--3-2 {
      animation: hideBlock19 $time0 forwards;
    }
    &--3-4 {
      animation: leftBlock19 $time0 forwards;
    }
    &--4-3 {
      animation: rightBlock19 $time0 forwards;
    }
    @media screen and (max-width: 1600px) {
      &--2-3 {
        animation: showBlock19-2 $time0 forwards;
      }
      &--3-2 {
        animation: hideBlock19-2 $time0 forwards;
      }
      &--3-4 {
        animation: leftBlock19-2 $time0 forwards;
      }
      &--4-3 {
        animation: rightBlock19-2 $time0 forwards;
      }
    }
    @media screen and (max-width: 1040px) {
      font-size: 200px;
      top: 100vh;
      right: auto;
      left: 15px;
      &--2-3 {
        animation: showBlock19-3 $time0 forwards;
      }
      &--3-2 {
        animation: hideBlock19-3 $time0 forwards;
      }
      &--3-4 {
        animation: leftBlock19-3 $time0 forwards;
      }
      &--4-3 {
        animation: rightBlock19-3 $time0 forwards;
      }
    }
    @media screen and (max-width: 600px) {
      font-size: 100px;
      right: auto;
      writing-mode: vertical-rl;
      transform: rotate(-180deg);
      line-height: 100%;
    }
  }
}
