.app {
  display: flex;
  flex-direction: column;
  padding: 80px 156px 127px;
  height: 100%;
  min-height: 100vh;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;

  @media screen and (max-width: 1600px) {
    padding: 80px 80px 127px;
  }
  @media screen and (max-width: 940px) {
    padding: 80px 60px 154px;
  }
  @media screen and (max-width: 600px) {
    padding: 25px 27px 139px;
  }
}

.app--songs {
  @media screen and (max-width: 1600px) and (min-width: 1040px) and (max-height: 800px) {
    padding: 80px 80px 70px;
  }
}

.test-container {
  width: 100%;
  height: 1000px;
}

a {
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
  -webkit-tap-highlight-color: transparent;
}
img {
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
  -webkit-tap-highlight-color: transparent;
}

button {
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
  -webkit-tap-highlight-color: transparent;
}
li {
  &:active,
  &:hover,
  &:focus {
    outline: 0;
    outline-offset: 0;
  }
  -webkit-tap-highlight-color: transparent;
}

/* &:active,
      &:hover,
      &:focus {
        outline: 0;
        outline-offset: 0;
      } */
