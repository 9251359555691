@import '../../../variables.scss';

.header {
  display: flex;
  justify-content: space-between;
  // position: relative;

  position: fixed;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  top: 0;
  left: 0;
  right: 0;
  box-sizing: border-box;
  padding: 40px 156px 0;
  z-index: 12;

  &--black {
    background: #191b25;
  }

  &__logo {
    cursor: pointer;
    width: 110px;
    height: 40px;
    object-fit: contain;
    object-position: left;
  }

  @media screen and (max-width: 1600px) {
    padding: 40px 80px 0;
  }
  @media screen and (max-width: 600px) {
    padding: 25px 27px 0;
  }

  &__menu {
    display: none;
    cursor: pointer;
  }

  &__nav {
    display: flex;
    &--mobile {
      display: none;
    }
  }

  &__link {
    @include fontText(normal, 600, 18px, 22px);
    /* identical to box height */

    display: flex;
    align-items: center;
    text-transform: uppercase;

    /* Main/01 */

    color: #52597a;
    margin-right: 83px;

    &:last-of-type {
      margin-right: 0;
    }

    &--active {
      color: #da4f4f;
      position: relative;

      &::after {
        content: '';
        background: #da4f4f;
        width: 6px;
        height: 6px;
        border-radius: 50%;
        position: absolute;
        bottom: 0;
        left: calc(50% - 3px);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    &__menu {
      display: block;
    }
    &__nav {
      display: none;
      &--mobile {
        display: flex;
      }
      background: #191b25;

      position: absolute;
      width: 100%;
      height: calc(100vh - 80px);
      top: 80px;
      left: 0;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;

      padding-top: 5vh;
    }

    &__link {
      margin-right: 0;
      @include fontText(normal, 300, 48px, 59px);
      margin-bottom: 76px;
      &::after {
        display: none;
      }
    }
  }
  @media screen and (max-width: 600px) {
    &__menu {
      display: block;
    }
    &__nav {
      top: 65px;
    }

    &__link {
      margin-right: 0;
      @include fontText(normal, 300, 32px, 39px);
      margin-bottom: 56px;
    }
  }
}
