@import '../../variables.scss';

.error-page {
  flex-grow: 1;
  //   background: linear-gradient(
  //     180deg,
  //     rgba(25, 27, 37, 0.0727273) 0%,
  //     rgba(25, 27, 37, 0) 64.06%,
  //     #191b25 100%
  //   );
  display: flex;
  align-items: center;
  justify-content: flex-end;
  &__title {
    @include fontTitle(normal, 900, 64px, 100%);

    color: #da4f4f;
    text-shadow: 2.01567px 4.03134px 24.188px rgba(24, 27, 37, 0.25),
      0px 50.6619px 126.655px rgba(4, 5, 12, 0.6);

    text-align: center;

    @media screen and (max-width: 768px) {
      @include fontTitle(normal, 900, 48px, 120%);
    }
  }

  &__img-container {
    position: fixed;
    left: 0;
    top: 0;
    z-index: -2;
  }

  &__img {
    height: 100vh;
    position: relative;
  }

  &__linear {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: linear-gradient(
      180deg,
      rgba(25, 27, 37, 0.0727273) 0%,
      rgba(25, 27, 37, 0) 64.06%,
      #191b25 100%
    );
    z-index: -1;
  }
}
