@import '../../../variables.scss';

.audio-container {
  color: white;
  width: 100%;
  margin-bottom: 100px;

  @media screen and (min-width: 1040px) and (max-height: 900px) {
    margin-bottom: 15px;
  }

  @media screen and (max-width: 600px) {
    margin-bottom: 60px;
  }

  &__canvas-container {
    position: relative;
  }

  &__canvas {
    width: 100%;
    height: 164px;
  }
  &__shadow {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background: transparent;
    z-index: 3;
    box-shadow: 80px 0 80px -50px #191b25 inset,
      -80px 0 80px -50px #191b25 inset;
  }
  &__controls-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  &__audio-track {
    width: 100%;
    height: 2px;
    background-color: #52597a;
    margin: 30px 0;
    position: relative;

    @media screen and (max-width: 1600px) and (min-width: 1040px) and (max-height: 800px) {
      margin: 20px 0;
    }
  }

  &__audio-track-active {
    width: 1px;
    transition: width 1s linear;
    height: 3px;
    background-color: #da4f4f;
  }

  &__audio-point {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background: #da4f4f;
    position: relative;
    top: -2px;
    left: 100%;
  }

  &__buttons-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
      width: 100%;
      justify-content: center;
      margin-top: 40px;
    }
  }

  &__button {
    background: none;
    border: none;
    box-shadow: none;
    cursor: pointer;

    &--prev {
      width: 56px;
      height: 56px;
      margin-right: 32px;
    }

    &--playOrPause {
      width: 72px;
      height: 72px;
    }

    &--next {
      width: 56px;
      height: 56px;
      margin-left: 32px;
    }

    &:disabled {
      opacity: 0.5;
    }
  }

  &__time {
    @include fontText(normal, 600, 14px, 17px);

    margin-left: 24px;
    color: #52597a;

    &--mobile {
      display: none;
    }

    @media screen and (max-width: 1024px) {
      display: none;

      &--mobile {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin: 0;
        margin-top: 22px;
      }
    }
  }

  &__range {
    -webkit-appearance: none;
    width: 100%;
    @include volume();
    transition: opacity 0.5s linear;

    &--hide {
      opacity: 0;
      transition: opacity 0.5s linear;
    }
  }

  &__volume-icon {
    &--active {
      path {
        fill: #da4f4f;
        transition: fill 0.5s linear;
      }
    }
  }
  &__volume-container {
    display: flex;
    align-items: center;

    @media screen and (max-width: 1024px) {
      display: none;
    }
  }

  // &__playOrPause {
  //   background: none;
  //   border: none;
  //   box-shadow: none;
  // }
}
