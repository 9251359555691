@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-ExtraBold.eot');
  src: local('Commissioner ExtraBold'), local('Commissioner-ExtraBold'),
    url('Commissioner-ExtraBold.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-ExtraBold.woff2') format('woff2'),
    url('Commissioner-ExtraBold.woff') format('woff'),
    url('Commissioner-ExtraBold.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Poppins';
  src: url('Poppins-Black.eot');
  src: local('Poppins Black'), local('Poppins-Black'),
    url('Poppins-Black.eot?#iefix') format('embedded-opentype'),
    url('Poppins-Black.woff2') format('woff2'),
    url('Poppins-Black.woff') format('woff'),
    url('Poppins-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Light.eot');
  src: local('Commissioner Light'), local('Commissioner-Light'),
    url('Commissioner-Light.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Light.woff2') format('woff2'),
    url('Commissioner-Light.woff') format('woff'),
    url('Commissioner-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Bold.eot');
  src: local('Commissioner Bold'), local('Commissioner-Bold'),
    url('Commissioner-Bold.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Bold.woff2') format('woff2'),
    url('Commissioner-Bold.woff') format('woff'),
    url('Commissioner-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Medium.eot');
  src: local('Commissioner Medium'), local('Commissioner-Medium'),
    url('Commissioner-Medium.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Medium.woff2') format('woff2'),
    url('Commissioner-Medium.woff') format('woff'),
    url('Commissioner-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-ExtraLight.eot');
  src: local('Commissioner ExtraLight'), local('Commissioner-ExtraLight'),
    url('Commissioner-ExtraLight.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-ExtraLight.woff2') format('woff2'),
    url('Commissioner-ExtraLight.woff') format('woff'),
    url('Commissioner-ExtraLight.ttf') format('truetype');
  font-weight: 200;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Black.eot');
  src: local('Commissioner Black'), local('Commissioner-Black'),
    url('Commissioner-Black.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Black.woff2') format('woff2'),
    url('Commissioner-Black.woff') format('woff'),
    url('Commissioner-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Regular.eot');
  src: local('Commissioner Regular'), local('Commissioner-Regular'),
    url('Commissioner-Regular.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Regular.woff2') format('woff2'),
    url('Commissioner-Regular.woff') format('woff'),
    url('Commissioner-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-SemiBold.eot');
  src: local('Commissioner SemiBold'), local('Commissioner-SemiBold'),
    url('Commissioner-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-SemiBold.woff2') format('woff2'),
    url('Commissioner-SemiBold.woff') format('woff'),
    url('Commissioner-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Commissioner';
  src: url('Commissioner-Thin.eot');
  src: local('Commissioner Thin'), local('Commissioner-Thin'),
    url('Commissioner-Thin.eot?#iefix') format('embedded-opentype'),
    url('Commissioner-Thin.woff2') format('woff2'),
    url('Commissioner-Thin.woff') format('woff'),
    url('Commissioner-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('CeraPro-Light.eot');
  src: local('Cera Pro Light'), local('CeraPro-Light'),
    url('CeraPro-Light.eot?#iefix') format('embedded-opentype'),
    url('CeraPro-Light.woff2') format('woff2'),
    url('CeraPro-Light.woff') format('woff'),
    url('CeraPro-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('CeraPro-Black.eot');
  src: local('Cera Pro Black'), local('CeraPro-Black'),
    url('CeraPro-Black.eot?#iefix') format('embedded-opentype'),
    url('CeraPro-Black.woff2') format('woff2'),
    url('CeraPro-Black.woff') format('woff'),
    url('CeraPro-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('CeraPro-Bold.eot');
  src: local('Cera Pro Bold'), local('CeraPro-Bold'),
    url('CeraPro-Bold.eot?#iefix') format('embedded-opentype'),
    url('CeraPro-Bold.woff2') format('woff2'),
    url('CeraPro-Bold.woff') format('woff'),
    url('CeraPro-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('CeraPro-Regular.eot');
  src: local('Cera Pro Regular'), local('CeraPro-Regular'),
    url('CeraPro-Regular.eot?#iefix') format('embedded-opentype'),
    url('CeraPro-Regular.woff2') format('woff2'),
    url('CeraPro-Regular.woff') format('woff'),
    url('CeraPro-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Cera Pro';
  src: url('CeraPro-Thin.eot');
  src: local('Cera Pro Thin'), local('CeraPro-Thin'),
    url('CeraPro-Thin.eot?#iefix') format('embedded-opentype'),
    url('CeraPro-Thin.woff2') format('woff2'),
    url('CeraPro-Thin.woff') format('woff'),
    url('CeraPro-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: block;
}
