@import './normalize.scss';
@import './public/font/stylesheet.css';
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #191b25;
  overflow-y: scroll;
}
.root {
  height: 100%;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
}

.root--100 {
  height: 100vh;
  max-height: 100vh;
  width: 100%;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body::-webkit-scrollbar-button {
  background-repeat: no-repeat;
  width: 3px;
  height: 0px;
}

body::-webkit-scrollbar-track {
  background: #191b25;
  border: 1px solid transparent;
  background-clip: content-box;
}

body::-webkit-scrollbar-thumb {
  -webkit-border-radius: 3px;
  border-radius: 3px;
  background-color: #da4f4f;
}

body::-webkit-scrollbar-thumb:hover {
  background-color: #191b25;
}

body::-webkit-resizer {
  background-repeat: no-repeat;
  width: 3px;
  height: 0px;
}

body::-webkit-scrollbar {
  width: 3px;
}
